import { useConnected } from 'components/Wallet/hooks';
import { getWalletIcon } from 'components/Wallet/icons';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import { Chain, EcosystemId } from 'types/emoney/Chain';
import { shortenAddress } from 'utils/address';
import s from './Summary.module.less';

const SummaryPanel = ({ chain, wallet }: { chain: Chain; wallet: string }) => {
  const { address } = useConnected(chain?.kind as EcosystemId);

  useStyles(s);
  return (
    <div className={s.summaryPanel}>
      <span className={s.title}>Your wallet address on {chain?.label}</span>
      <div className={s.walletInfo}>
        {wallet && <img src={getWalletIcon(wallet as string)} />}

        <span className={s.address}>{shortenAddress(address)}</span>
      </div>
    </div>
  );
};
export default SummaryPanel;
