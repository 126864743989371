import { useChains } from 'components/Chain/hooks';
import { sortChains } from 'components/Chain/util';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import { useContext } from 'react';

import { getSrc } from 'components/Icons/ChainIcon';
import Button from 'customer/components/emoney/Wallet/Wizard/Buttons/Button';
import ButtonGroup from 'customer/components/emoney/Wallet/Wizard/Buttons/ButtonGroup';
import { WizardContext } from '../../Provider';
import s from './Chain.module.less';

const ChainSelection = () => {
  useStyles(s);
  const { setChainToLink, setStep } = useContext(WizardContext);
  const chains = useChains();

  const getDescription = (chain: string) => {
    if (chain === 'gnosis') {
      return ' The majority of the liquidity is on Gnosis. Swap using Balancer, Curve, or CowSwap; Supply and borrow on Aave; Get your Gnosis Pay debit card.';
    }
    if (chain === 'noble') {
      return 'The gateway to 50+ application-specific blockchains including dYdX, Osmosis, Sei, and more. EURe is used as the fee token when interacting with these applications.';
    }
    if (chain === 'arbitrum') {
      return "New launch! We're seeking pioneers to help bootstrap liquidity and activity on Arbitrum.";
    }
    if (chain === 'linea') {
      return "New launch! We're seeking pioneers to help bootstrap liquidity and activity on Linea.";
    }
    if (chain === 'camino' || chain === 'columbus') {
      return "Camino Network is the L1 specialized for travel. EURe is used on Camino's Messenger dApp to sell/buy travel products globally and also as the stablecoin of choice in dApps from major travel partners.";
    }
    return undefined;
  };

  return (
    <>
      <ButtonGroup>
        {sortChains(chains).map((chain) => {
          const description = getDescription(chain.chain);
          return (
            <Button
              key={chain.id}
              icon={getSrc(chain.chain, true)}
              onClick={() => {
                setChainToLink(chain);
                setStep('WALLET_SELECTION');
              }}
            >
              <div className={s.buttonContent}>
                {chain.label}
                {description && (
                  <span className={s.description}>{description}</span>
                )}
              </div>
            </Button>
          );
        })}
      </ButtonGroup>
    </>
  );
};
export default ChainSelection;
