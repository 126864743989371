import { constants } from '@monerium/sdk';
import { useDisconnect } from 'components/Wallet/hooks';
import { SignatureData } from 'components/Wallet/types';
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Chain } from 'types/emoney/Chain';

export type WizardSteps =
  | 'CHAIN_SELECTION'
  | 'WALLET_SELECTION'
  | 'PROVE_OWNERSHIP';
export type WizardKind = 'LINK' | 'REDEEM' | 'CONNECT';

export const WizardContext = createContext<{
  chainToLink?: Chain;
  setChainToLink: Dispatch<SetStateAction<Chain | undefined>>;
  walletToLink?: string;
  setWalletToLink: Dispatch<SetStateAction<string | undefined>>;
  step: WizardSteps;
  setStep: Dispatch<SetStateAction<WizardSteps>>;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  reconnect: () => void;
  onSuccess: (
    signatureData: SignatureData,
    chain?: Chain,
    address?: string,
  ) => Promise<void>;
  messageToSign: string;
}>({
  chainToLink: undefined,
  setChainToLink: () => {},
  walletToLink: undefined,
  setWalletToLink: () => {},
  step: 'CHAIN_SELECTION',
  setStep: () => {},
  open: false,
  setOpen: () => {},
  reconnect: () => {},
  onSuccess: async () => {},
  messageToSign: constants.LINK_MESSAGE,
});

/**
 * Manage signature process of linking accounts.
 */
export const WizardProvider = ({
  children,
  isOpen = false,
  onSuccess,
}: {
  children: React.ReactNode;
  isOpen?: boolean;
  onSuccess: (signatureData: SignatureData, chain?: Chain) => Promise<void>;
}) => {
  const initialStep: WizardSteps = 'CHAIN_SELECTION';
  const messageToSign = constants.LINK_MESSAGE;

  const [chainToLink, setChainToLink] = useState<Chain | undefined>(undefined);
  const [walletToLink, setWalletToLink] = useState<string | undefined>(
    undefined,
  );
  const [step, setStep] = useState<WizardSteps>(initialStep);
  const [open, setOpen] = useState(isOpen);
  const { disconnect } = useDisconnect();

  useEffect(() => {
    if (!open) {
      // Reset wizard when modal is closed
      setStep(initialStep);
    }
  }, [open]);

  const reconnect = () => {
    disconnect();
    setStep(initialStep);
    setChainToLink(undefined);
    setWalletToLink(undefined);
  };

  const contextValues = useMemo(
    () => ({
      chainToLink,
      setChainToLink,
      walletToLink,
      setWalletToLink,
      step,
      setStep,
      open,
      setOpen,
      reconnect,
      messageToSign,
      onSuccess,
    }),
    [chainToLink, walletToLink, step, open],
  );

  return (
    <WizardContext.Provider value={contextValues}>
      {children}
    </WizardContext.Provider>
  );
};
