import { useChain } from 'components/Chain/hooks';
import { useContext, useLayoutEffect } from 'react';
import { useAccount as useWagmiAccount } from 'wagmi';

import { useIsConnectedSafe } from 'customer/components/emoney/Wallet/hooks/useIsSafe';
import { EVMChainId } from 'types/emoney/Chain';
import { WizardContext } from '../Provider';
import ChainSelection from './Chains';
import ProofOwnership from './Sign';
import WalletSelection from './Wallets';

const AccountLinkWizard = () => {
  const { step, setStep, setChainToLink, setWalletToLink, open } =
    useContext(WizardContext);
  const { connector, chainId: connectedChainId } = useWagmiAccount();
  const isConnectedSafe = useIsConnectedSafe();

  const { chain } = useChain(connectedChainId as EVMChainId);

  useLayoutEffect(() => {
    // if Safe is connected, go directly to PROVE_OWNERSHIP step
    if (isConnectedSafe && connector?.id === 'safe') {
      setWalletToLink(connector.id);
      setChainToLink(chain);
      setStep('PROVE_OWNERSHIP');
    }
  }, []);

  if (!open) {
    return null;
  }

  return (
    <>
      {step === 'CHAIN_SELECTION' && <ChainSelection />}
      {step === 'WALLET_SELECTION' && <WalletSelection />}
      {step === 'PROVE_OWNERSHIP' && <ProofOwnership />}
    </>
  );
};
export default AccountLinkWizard;
